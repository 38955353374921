/* eslint max-len: 0 */
import Enum from 'enum';

/**
 * Enum defining all available api v1 & some root urls for "payments and billing" resources and their URL template
 */
export const Resources = new Enum({
  DeductionDefinitions: 'deduction_definitions',
  ExportNachaFileBatches: 'export_nacha_file_batch',
  ExportNachaFileBatchItemsUnlock: 'export_nacha_file_batch_items/:exportNachaFileBatchItemId/unlock',
  FacilitiesChargeMemos: 'facilities_charge_memos',
  FacilityChargeMemo: 'facilities_charge_memos/:facilityChargeMemoId',
  FacilityChargeMemoTypes: 'facility_charge_memo_types',
  FacilityCreditMemo: 'facilities_credit_memos/:facilityCreditMemoId',
  FacilitiesCreditMemos: 'facilities_credit_memos',
  FacilityCreditMemoReverse: 'facilities_credit_memos/:facilityCreditMemoId/reverse',
  FacilityCreditMemoTypes: 'facility_credit_memo_types',
  FacilityBillingOverviewSummary: 'billing_overview/:facility_id',
  FacilityBillingOverviewInvoiceHistory: 'billing_overview/:facilityId/invoice_history',
  FacilityBillingOverviewTransactions: 'billing_overview/:facilityId/transactions',
  FacilityInvoices: 'facilities/:facilityId/invoices',
  Factoring: 'factoring',
  Invoice: 'invoices/:invoiceId',
  InvoiceCharge: 'invoice_charges/:chargeId',
  InvoiceCharges: 'invoice_charges',
  InvoiceEntries: 'invoices/:invoiceId/entries',
  InvoiceIdCharges: 'invoices/:invoiceId/charges',
  InvoiceAppliedCredits: 'invoices/:invoiceId/applied_credits',
  InvoiceNewPayment: 'invoice_payments',
  InvoicePayments: 'invoices/:invoiceId/payments',
  InvoicePaymentsId: 'invoice_payments/:paymentId',
  CreditLimit: 'insights/credit_limit',
  Invoices: 'invoices',
  InvoiceWorkLogBatchDownloadLink: 'invoices/:invoiceId/work_log_batch_download_link',
  MonthlyCommissionHoursSummary: 'monthly_commission_hours_summary',
  PaymentMethods: 'payment-methods',
  PaymentMethodsCompletedOn: 'payment-methods/:userPaymentMethodId/completed-on',
  PaymentReportSummary: 'payment_report_summary',
  Unpaid: 'unpaid',
  UnpaidReportSummary: 'unpaid_report_summary',
  UnpaidReportNachaExportCheck: 'unpaid_report_nacha_export_check',
  UnpaidSummary: 'unpaid_summary',
  Provider1099s: 'providers/:provider_id/1099s',
  Provider1099Ks: 'providers/:provider_id/1099s/1099-K',
  ProviderCredits: 'provider_credits',
  ProviderCreditTypes: 'provider_credit_types',
  ProviderDeduction: 'providers/:providerId/deductions/:providerDeductionId',
  ProviderDeductions: 'providers/:providerId/deductions',
  ProviderPayments: 'provider_payments',
  ProviderPayment: 'provider_payments/:earningId',
  ProviderPaymentsSummary: 'provider_payments_summary',
  PaymentsReportPdf: 'payments_report_pdf',
  ProvidersDeductions: 'provider_deductions',
  ProvidersDeductionsDeactivate: 'provider_deductions/:providerDeductionId/deactivate',
  ProvidersDeductionsActivate: 'provider_deductions/:providerDeductionId/activate',
  ProvidersPaymentMethod: 'providers/:providerId/payment_method',
  ProviderCredit: 'provider_credits/:providerCreditId',
  OnBoardingPaymentMethod: 'onboarding/:onBoardingId/payment_method',
  ShiftPayment: 'shift_payments/:shiftPaymentId',
  ShiftPaymentEntry: 'shift_payment_entries/:entryId',
  ShiftPayments: 'shift_payments',
  ShiftPaymentsUnlock: 'shift_payments/unlock',
  UserPaycardAccount: 'users/:userId/paycard_account',
  RapidInstantTransactions: 'rapid_instant_transactions',

  // Non-API prefixed endpoints
  UnpaidReportRapidCsvExport: 'unpaid_payout_rapid_csv_export',
  UnpaidReportNachaExport: 'unpaid_report_nacha_export',
  UnpaidReportNachaInvalidExport: 'unpaid_report_nacha_invalid',
  UnpaidReportXlsExport: 'unpaid_report_xls_export',
});
